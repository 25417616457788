import axios from "../plugins/axios_instance";

export const hobiService = {
  loadAll,
  getAll,
  create,
  destroy,
  update,
};

function loadAll() {
  return axios.get("/hobi/all").then((response) => {
    let hobi = response.data;
    return hobi;
  });
}

function getAll(limit, search, page, sort, order, kategori_hobi_id) {
  return axios
    .get("/hobi", {
      params: {
        limit,
        nama: search,
        page,
        order_by: sort,
        order_type: order,
        kategori_hobi_id,
      },
    })
    .then((response) => {
      let hobi = response.data;
      return hobi;
    });
}

function create(hobi) {
  return axios.post("/hobi", { ...hobi });
}

function update(hobi, id) {
  return axios.put(`/hobi/${id}`, hobi);
}

function destroy(id) {
  return axios.delete(`/hobi/${id}`);
}
