import axios from "../plugins/axios_instance";

export const kategoriHobiService = {
  getAll,
  create,
  getOne,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/kategori_hobi", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let kategori_hobi = response.data;
      return kategori_hobi;
    });
}

function create(kategori_hobi) {
  return axios.post("/kategori_hobi", { ...kategori_hobi });
}

function getOne(id) {
  return axios.get(`/kategori_hobi/${id}`);
}

function update(kategori_hobi, id) {
  return axios.put(`/kategori_hobi/${id}`, { ...kategori_hobi });
}

function destroy(id) {
  return axios.delete(`/kategori_hobi/${id}`);
}
