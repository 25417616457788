import axios from "../plugins/axios_instance";

export const dashboardService = {
  getTotalAnggota,
  getPendaftarHariIni,
  getWilayahAnggotaPalingBanyak,
  getAnggotaMenungguPembayaran,
  getTotalStatusKTA,
  getFrekuensiPendaftar,
};

function getTotalAnggota(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/total_anggota`, {
    params: {
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}

function getPendaftarHariIni(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/pendaftar_hari_ini`, {
    params: {
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}

function getWilayahAnggotaPalingBanyak(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/wilayah_anggota_paling_banyak`, {
    params: {
      limit: 10,
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}

function getAnggotaMenungguPembayaran(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/menunggu_pembayaran`, {
    params: {
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}

function getTotalStatusKTA(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/total_status_kta`, {
    params: {
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}

function getFrekuensiPendaftar(provinsi_id, kota_id, kecamatan_id) {
  return axios.get(`/dashboard/frekuensi_pendaftar`, {
    params: {
      propinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
    },
  });
}
