<template>
  <div>
    <nav
      class="navbar navbar-expand-sm navbar-dark bg-dark"
      aria-label="Third navbar example"
    >
      <div class="container-fluid">
        <router-link to="/admin" class="navbar-brand">
          <img
            src="@/assets/logo.png"
            alt="Logo XTC Indonesia"
            srcset=""
            width="52px"
          />
        </router-link>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample03"
          aria-controls="navbarsExample03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbarsExample03" style="">
          <ul class="navbar-nav me-auto mb-2 mb-sm-0">
            <li class="nav-item">
              <router-link to="/admin" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/admin/anggota" class="nav-link"
                >Daftar Anggota</router-link
              >
            </li>
            <!-- If role admin -->
            <li
              class="nav-item dropdown"
              v-if="currentUser.role === 'Super User'"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="dropdown03"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Master Data</a
              >
              <ul class="dropdown-menu" aria-labelledby="dropdown03">
                <li>
                  <router-link to="/admin/negara" class="dropdown-item"
                    >Negara</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/provinsi" class="dropdown-item"
                    >Provinsi</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/kota" class="dropdown-item"
                    >Kota</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/kecamatan" class="dropdown-item"
                    >Kecamatan</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/kelurahan" class="dropdown-item"
                    >Kelurahan</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link to="/admin/kategori-hobi" class="dropdown-item"
                    >Kategori Hobi</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/hobi" class="dropdown-item"
                    >Hobi</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/kategori-pekerjaan"
                    class="dropdown-item"
                    >Kategori Pekerjaan</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/pekerjaan" class="dropdown-item"
                    >Pekerjaan</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link to="/admin/users" class="dropdown-item"
                    >Pengguna</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link to="/admin/merchandise" class="dropdown-item"
                    >Merchandise</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item" v-if="currentUser.role === 'Super User'">
              <router-link to="/admin/cetak-qr" class="nav-link"
                >Cetak Kode QR</router-link
              >
            </li>
            <li class="nav-item" v-if="currentUser.role === 'Super User'">
              <a href="" class="nav-link" @click.prevent="printOut"
                >Printout QR</a
              >
            </li>
            <li class="nav-item" v-if="currentUser.role === 'Super User'">
              <router-link to="/admin/claim-merchandise" class="nav-link"
                >Claim Merchandise</router-link
              >
            </li>
          </ul>
        </div>

        <button
          class="btn btn-outline-secondary position-relative"
          @click="openNotifications"
        >
          <i class="bi bi-bell">
            <span
              class="
                position-absolute
                top-0
                start-100
                translate-middle
                p-2
                bg-danger
                border border-light
                rounded-circle
              "
              v-if="newNotifications"
            >
            </span>
          </i>
        </button>
        <div class="dropdown">
          <a
            class="nav-link dropdown-toggle white--text"
            id="userMenuDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
              width="40"
              height="40"
              class="rounded-circle"
            />
          </a>
          <ul class="dropdown-menu" aria-labelledby="userMenuDropdown">
            <li>
              <router-link class="dropdown-item" to="/admin"
                >Admin panel</router-link
              >
            </li>
            <li>
              <router-link class="dropdown-item" to="/change_password"
                >Ganti password</router-link
              >
            </li>
            <li><a href="" class="dropdown-item" @click="logout">Keluar</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <main class="container-fluid">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import { getToken, onMessage } from "firebase/messaging";
import { authenticationService, registrationTokenService } from "@/services";
import { Role } from "@/helpers";
import messaging from "@/helpers/firebase";

export default {
  data() {
    return {
      newNotifications: false,
      currentUser: authenticationService.currentUserValue,
    };
  },
  methods: {
    openNotifications() {
      this.newNotifications = false;
      this.$router.push({ name: "admin-notifikasi" });
    },
    logout() {
      authenticationService.logout();
      location.reload();
    },
    printOut() {
      let routeData = this.$router.resolve({
        name: "printout-qr-code",
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    Notification.requestPermission();
    if (authenticationService.currentUserValue.role === Role.SuperUser) {
      onMessage(messaging, (payload) => {
        this.newNotifications = true;
        this.$toast.open({
          message: payload.data.body,
          type: "info",
          duration: 8000,
          onClick: () =>
            this.$router.push({
              name: "admin-anggota-detail",
              params: { id: payload.data.anggota_id },
            }),
        });
        // ...
      });
      getToken(messaging, {
        vapidKey:
          "BDFwRUFcaPErNDtr7WdiiRvYxVGX_-fgImu4ifQs2Hm4fF0W41GKpy1mJIKQ30MPMRVYvaLe2L4tADASgpNSU6Y",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            registrationTokenService.checkToken(currentToken);
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  },
};
</script>

<style>
.dropdown-menu[data-bs-popper] {
  left: -85px;
}
</style>
