import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/axios";
import VCalendar from "v-calendar";
import Vuelidate from "vuelidate";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { vfmPlugin } from "vue-final-modal";
import "filepond/dist/filepond.min.css";

// eslint-disable-next-line no-unused-vars
import firebaseApp from "./helpers/firebase";

import store from "./store";

Vue.config.productionTip = false;

Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.component("v-select", vSelect);
Vue.use(vfmPlugin);

// Vue.prototype.$messaging = firebaseMessaging;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
