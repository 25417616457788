"use strict";

// import { authenticationService } from "@/services/auth.service";
import axios from "axios";
import { authenticationService } from "@/services/auth.service";

let config = {
  // baseURL: process.env.API_URL || "http://localhost:4000/api",
  baseURL: process.env.VUE_APP_API_URL || "/api",
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    config.headers = {
      Accept: "application/json",
      Authorization:
        `Bearer ${
          authenticationService.currentUserValue &&
          authenticationService.currentUserValue.token
        }` || "",
    };
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
