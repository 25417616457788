import Anggota from "./Layout.vue";
import { Role } from "@/helpers";
const routes = [
  {
    path: "/anggota",
    component: Anggota,
    children: [
      {
        path: "/",
        name: "anggota-home",
        meta: { authorize: [Role.Anggota] },
        component: () => import("./home/Home.vue"),
      },
    ],
  },
];

export default routes;
