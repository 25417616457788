import axios from "../plugins/axios_instance";

export const notificationService = {
  getAll,
  checkNewNotifications,
  updateNotificationReaded,
};

function getAll(limit, page) {
  return axios.get("/notification", {
    params: {
      limit: limit,
      page: page,
      order_by: "createdAt",
      order_type: "DESC",
    },
  });
}

function checkNewNotifications() {
  return axios.post("/notification/check_new_notifications");
}

function updateNotificationReaded(id) {
  return axios.put(`/notification/${id}/mark_as_readed`);
}
