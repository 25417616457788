import axios from "../plugins/axios_instance";

export const qrCodeService = {
  getAll,
  getByCode,
  generate,
  printOut,
  printOutUnusedCode,
  assignToMember,
  assignToMerchandise,
  generateForAnggota,
  unclaimQrCode,
};

function getAll(limit, search, page, sort, order) {
  const { status, code } = search;
  return axios
    .get("/cetak-qr-code", {
      params: {
        limit,
        status,
        code,
        page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let qrcodes = response.data;
      return qrcodes;
    });
}

function getByCode(code) {
  return axios.get(`/cetak-qr-code/code/${code}`);
}

function generate(body) {
  return axios.post("/cetak-qr-code/generate", body);
}

function generateForAnggota(body) {
  return axios.post("/cetak-qr-code/generate_for_anggota", body);
}

function assignToMember(body) {
  return axios.post(`/cetak-qr-code/${body.id}/assign_to_member`, body);
}

function assignToMerchandise(body) {
  return axios.post(`/cetak-qr-code/${body.id}/assign_to_merchandise`, body);
}

function unclaimQrCode(id) {
  return axios.post(`/cetak-qr-code/${id}/unclaim`);
}

function printOut() {
  return axios.get("/cetak-qr-code/printout").then((response) => {
    let qrcodes = response.data;
    return qrcodes;
  });
}

function printOutUnusedCode() {
  return axios.get("/cetak-qr-code/printout_unclaim").then((response) => {
    let qrcodes = response.data;
    return qrcodes;
  });
}
