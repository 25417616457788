import axios from "../plugins/axios_instance";

export const userService = {
  getAll,
  create,
  getOne,
  update,
  destroy,
  updatePassword,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/users", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let users = response.data;
      return users;
    });
}

function create(users) {
  return axios.post("/users", { ...users });
}

function getOne(id) {
  return axios.get(`/users/${id}`);
}

function update(users, id) {
  return axios.put(`/users/${id}`, { ...users });
}

function destroy(id) {
  return axios.delete(`/users/${id}`);
}

function updatePassword({
  current_password,
  new_password,
  password_confirmation,
}) {
  return axios.put(`/users/change_password`, {
    current_password,
    new_password,
    password_confirmation,
  });
}
