import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDYNb4BCpxOVO8MtOF9TIvvbAyzK0ssaDQ",
  authDomain: "mylab-a59d1.firebaseapp.com",
  databaseURL: "https://mylab-a59d1.firebaseio.com",
  projectId: "mylab-a59d1",
  storageBucket: "mylab-a59d1.appspot.com",
  messagingSenderId: "6864853455",
  appId: "1:6864853455:web:35691fd82121267265daba",
  measurementId: "G-77MJV31N2F",
};

// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
export default messaging;
