import axios from "../plugins/axios_instance";

export const merchandiseService = {
  getAll,
  getUnclaimed,
  create,
  getOne,
  update,
  destroy,
  assignToMember,
  unclaimMerchandise,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/merchandise", {
      params: {
        limit: limit,
        nama_produk: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let merchandise = response.data;
      return merchandise;
    });
}

function getUnclaimed(limit, search, page, sort, order) {
  return axios.get("/merchandise/unclaimed", {
    params: {
      limit: limit,
      nama_produk: search,
      page: page,
      order_by: sort,
      order_type: order,
    },
  });
}

function create(merchandise) {
  var bodyFormData = new FormData();
  bodyFormData.append("nama_produk", merchandise.nama_produk);
  bodyFormData.append("nama_vendor", merchandise.nama_vendor);
  bodyFormData.append("ukuran", merchandise.ukuran);
  bodyFormData.append("harga", merchandise.harga);
  bodyFormData.append("jenis_bahan", merchandise.jenis_bahan);

  bodyFormData.append("foto_produk_1_path", merchandise.foto_produk_1_path);
  bodyFormData.append("foto_produk_2_path", merchandise.foto_produk_2_path);

  return axios({
    method: "post",
    url: "/merchandise",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function getOne(id) {
  return axios.get(`/merchandise/${id}`);
}

function update(merchandise) {
  var bodyFormData = new FormData();
  bodyFormData.append("nama_produk", merchandise.nama_produk);
  bodyFormData.append("nama_vendor", merchandise.nama_vendor);
  bodyFormData.append("ukuran", merchandise.ukuran);
  bodyFormData.append("harga", merchandise.harga);
  bodyFormData.append("jenis_bahan", merchandise.jenis_bahan);

  if (merchandise.foto_produk_1_path)
    bodyFormData.append("foto_produk_1_path", merchandise.foto_produk_1_path);
  if (merchandise.foto_produk_2_path)
    bodyFormData.append("foto_produk_2_path", merchandise.foto_produk_2_path);

  return axios({
    method: "put",
    url: `/merchandise/${merchandise.id}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function destroy(id) {
  return axios.delete(`/merchandise/${id}`);
}

function assignToMember(body) {
  return axios.post(`/merchandise/${body.id}/assign_to_member`, body);
}

function unclaimMerchandise(id) {
  return axios.post(`/merchandise/${id}/unclaim`);
}
