import axios from "../plugins/axios_instance";

export const kelurahanService = {
  getAll,
  create,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order, kecamatan_id) {
  return axios
    .get("/kelurahan", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
        kecamatan_id: kecamatan_id,
      },
    })
    .then((response) => {
      let kecamatan = response.data;
      return kecamatan;
    });
}

function create(kelurahan) {
  return axios.post("/kelurahan", { ...kelurahan });
}

function update(kelurahan, id) {
  return axios.put(`/kelurahan/${id}`, kelurahan);
}

function destroy(kelurahan_id) {
  return axios.delete(`/kelurahan/${kelurahan_id}`);
}
