import axios from "../plugins/axios_instance";

export const provinsiService = {
  getAll,
  create,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/propinsi", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let provinsi = response.data;
      return provinsi;
    });
}

function create(provinsi) {
  return axios.post("/propinsi", { ...provinsi });
}

function update(provinsi, id) {
  return axios.put(`/propinsi/${id}`, provinsi);
}

function destroy(propinsi_id) {
  return axios.delete(`/propinsi/${propinsi_id}`);
}
