import axios from "../plugins/axios_instance";

export const countryService = {
  getAll,
  create,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/countries", {
      params: {
        limit: limit,
        name: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let countries = response.data;
      return countries;
    });
}

function create(country) {
  return axios.post("/countries", { ...country });
}

function update(country, id) {
  return axios.put(`/countries/${id}`, country);
}

function destroy(country_id) {
  return axios.delete(`/countries/${country_id}`);
}
