import axios from "../plugins/axios_instance";

export const kotaService = {
  getAll,
  create,
  destroy,
  update,
};

function getAll(limit, search, page, sort, order, provinsi_id) {
  return axios
    .get("/kota", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
        propinsi_id: provinsi_id,
      },
    })
    .then((response) => {
      let kota = response.data;
      return kota;
    });
}

function create(kota) {
  return axios.post("/kota", { ...kota });
}

function update(kota, id) {
  return axios.put(`/kota/${id}`, kota);
}

function destroy(kota_id) {
  return axios.delete(`/kota/${kota_id}`);
}
