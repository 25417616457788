import axios from "../plugins/axios_instance";

export const kecamatanService = {
  getAll,
  create,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order, kota_id) {
  return axios
    .get("/kecamatan", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
        kota_id: kota_id,
      },
    })
    .then((response) => {
      let kecamatan = response.data;
      return kecamatan;
    });
}

function create(kecamatan) {
  return axios.post("/kecamatan", { ...kecamatan });
}

function update(kecamatan, id) {
  return axios.put(`/kecamatan/${id}`, kecamatan);
}

function destroy(kecamatan_id) {
  return axios.delete(`/kecamatan/${kecamatan_id}`);
}
