import Admin from "./Layout.vue";
import { Role } from "@/helpers";
const routes = [
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "/",
        name: "admin-dashboard",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./dashboard/Dashboard.vue"),
      },
      {
        path: "notifikasi",
        name: "admin-notifikasi",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./notifikasi/Notifikasi.vue"),
      },
      {
        path: "anggota",
        name: "admin-anggota",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./anggota/Anggota.vue"),
      },
      {
        path: "anggota/show/:id",
        name: "admin-anggota-detail",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./anggota/AnggotaDetail.vue"),
      },
      {
        path: "anggota/edit/:id",
        name: "admin-anggota-edit",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./anggota/AnggotaEdit.vue"),
      },
      {
        path: "anggota/new",
        name: "admin-anggota-add",
        meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
        component: () => import("./anggota/AnggotaAdd.vue"),
      },
      {
        path: "anggota/import",
        name: "admin-anggota-import",
        meta: { authorize: [Role.SuperUser, Role.Admin] },
        component: () => import("./anggota/AnggotaImport.vue"),
      },
      {
        path: "negara",
        name: "admin-negara",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./negara/Negara.vue"),
      },
      {
        path: "provinsi",
        name: "admin-provinsi",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./provinsi/Provinsi.vue"),
      },
      {
        path: "kota",
        name: "admin-kota",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./kota/Kota.vue"),
      },
      {
        path: "kecamatan",
        name: "admin-kecamatan",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./kecamatan/Kecamatan.vue"),
      },
      {
        path: "kelurahan",
        name: "admin-kelurahan",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./kelurahan/Kelurahan.vue"),
      },
      {
        path: "users",
        name: "admin-users",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./users/Users.vue"),
      },
      {
        path: "cetak-qr",
        name: "admin-cetak-qr",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./cetak-qr/CetakQr.vue"),
      },
      {
        path: "kategori-hobi",
        name: "admin-kategori-hobi",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./kategori-hobi/KategoriHobi.vue"),
      },
      {
        path: "hobi",
        name: "admin-hobi",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./hobi/Hobi.vue"),
      },
      {
        path: "kategori-pekerjaan",
        name: "admin-kategori-pekerjaan",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./kategori-pekerjaan/KategoriPekerjaan.vue"),
      },
      {
        path: "pekerjaan",
        name: "admin-pekerjaan",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./pekerjaan/Pekerjaan.vue"),
      },
      {
        path: "merchandise",
        name: "admin-merchandise",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./merchandise/Merchandise.vue"),
      },
      {
        path: "claim-merchandise",
        name: "admin-claim-merchandise",
        meta: { authorize: [Role.SuperUser] },
        component: () => import("./claim-merchandise/ClaimMerchandise.vue"),
      },
    ],
  },
];

export default routes;
