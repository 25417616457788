import axios from "../plugins/axios_instance";

export const kategoriPekerjaanService = {
  getAll,
  create,
  getOne,
  update,
  destroy,
};

function getAll(limit, search, page, sort, order) {
  return axios
    .get("/kategori_pekerjaan", {
      params: {
        limit: limit,
        nama: search,
        page: page,
        order_by: sort,
        order_type: order,
      },
    })
    .then((response) => {
      let kategori_pekerjaan = response.data;
      return kategori_pekerjaan;
    });
}

function create(kategori_pekerjaan) {
  return axios.post("/kategori_pekerjaan", { ...kategori_pekerjaan });
}

function getOne(id) {
  return axios.get(`/kategori_pekerjaan/${id}`);
}

function update(kategori_pekerjaan, id) {
  return axios.put(`/kategori_pekerjaan/${id}`, { ...kategori_pekerjaan });
}

function destroy(id) {
  return axios.delete(`/kategori_pekerjaan/${id}`);
}
