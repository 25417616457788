// import { handleResponse, requestOptions } from "@/_helpers";
import axios from "../plugins/axios_instance";
import XLSX from "xlsx";

export const anggotaService = {
  loadAll,
  getAll,
  getById,
  getByUserId,
  getByQrCode,
  create,
  approve,
  reject,
  confirmPayment,
  confirmProduction,
  getFotoDiri,
  getFotoIdentitas,
  getFotoMemegangIdentitas,
  getFotoBuktiPembayaran,
  destroy,
  uploadBuktiPembayaran,
  uploadFotoIdentitas,
  uploadFotoMemegangIdentitas,
  uploadFotoDiri,
  update,
  resendEmailInfoPembayaran,
  resendEmailInfoKTAOnProgress,
  resendEmailInfoApproved,
  getWithoutQrCode,
  exportToExcel,
};

function loadAll(nama) {
  return axios.get(`/anggota/all`, { query: { nama } });
}

function getAll(limit, search, page, sort, order) {
  const {
    no_kta,
    nama,
    email,
    no_identitas,
    provinsi_id,
    kota_id,
    kecamatan_id,
    kelurahan_id,
    status,
    jenis_atribut,
    pekerjaan_id,
    hobi_id,
    createdAt,
  } = search;
  return axios
    .get("/anggota", {
      params: {
        limit: limit,
        no_kta: no_kta,
        nama: nama,
        email: email,
        no_identitas: no_identitas,
        provinsi_id: provinsi_id,
        kota_id: kota_id,
        kecamatan_id: kecamatan_id,
        kelurahan_id: kelurahan_id,
        status: status,
        jenis_atribut: jenis_atribut,
        pekerjaan_id: pekerjaan_id,
        hobi_id: hobi_id,
        page: page,
        order_by: sort,
        order_type: order,
        createdAt: createdAt,
      },
    })
    .then((response) => {
      let anggota = response.data;
      return anggota;
    });
}

function getById(id) {
  return axios.get(`/anggota/${id}`);
}

function getByUserId(id) {
  return axios.get(`/anggota/by_user/${id}`);
}

function getByQrCode(qr_code) {
  return axios.get(`/anggota/by_qr_code/${qr_code}`);
}

function getWithoutQrCode() {
  return axios.get(`/anggota/without_qr_code/`);
}

function create(anggota) {
  var bodyFormData = new FormData();
  bodyFormData.append("nama_lengkap", anggota.nama_lengkap);
  bodyFormData.append("nama_panggilan", anggota.nama_panggilan);
  bodyFormData.append("jenis_kelamin", anggota.jenis_kelamin);
  bodyFormData.append("tanggal_lahir", anggota.tanggal_lahir);
  bodyFormData.append("email", anggota.email);
  bodyFormData.append("no_hp", anggota.no_hp);
  bodyFormData.append("agama", anggota.agama);
  bodyFormData.append("status", anggota.status);
  bodyFormData.append("jenis_identitas", anggota.jenis_identitas);
  bodyFormData.append("no_identitas", anggota.no_identitas);

  bodyFormData.append("alamat", anggota.alamat);
  bodyFormData.append("propinsi_id", anggota.propinsi_id);
  bodyFormData.append("kota_id", anggota.kota_id);
  bodyFormData.append("kecamatan_id", anggota.kecamatan_id);
  bodyFormData.append("kelurahan_id", anggota.kelurahan_id);

  bodyFormData.append("kategori_hobi_id", anggota.kategori_hobi_id);
  bodyFormData.append("hobi_id", anggota.hobi_id);
  bodyFormData.append("kategori_pekerjaan_id", anggota.kategori_pekerjaan_id);
  bodyFormData.append("pekerjaan_id", anggota.pekerjaan_id);

  bodyFormData.append("foto_identitas", anggota.foto_identitas);
  bodyFormData.append(
    "foto_memegang_identitas",
    anggota.foto_memegang_identitas
  );
  bodyFormData.append("foto_diri", anggota.foto_diri);
  bodyFormData.append("bukti_pembayaran", anggota.bukti_pembayaran);
  bodyFormData.append("jenis_atribut", anggota.jenis_atribut);
  bodyFormData.append("ukuran_atribut", anggota.ukuran_atribut);
  bodyFormData.append("status_anggota", anggota.status_anggota);
  bodyFormData.append("nomor_kta", anggota.nomor_kta);
  bodyFormData.append("qr_code", anggota.qr_code);

  bodyFormData.append("ahli_waris_1_nik", anggota.ahli_waris_1_nik);
  bodyFormData.append("ahli_waris_1_nama", anggota.ahli_waris_1_nama);
  bodyFormData.append("ahli_waris_1_no_hp", anggota.ahli_waris_1_no_hp);
  bodyFormData.append("ahli_waris_1_hubungan", anggota.ahli_waris_1_hubungan);
  bodyFormData.append("ahli_waris_2_nik", anggota.ahli_waris_2_nik);
  bodyFormData.append("ahli_waris_2_nama", anggota.ahli_waris_2_nama);
  bodyFormData.append("ahli_waris_2_no_hp", anggota.ahli_waris_2_no_hp);
  bodyFormData.append("ahli_waris_2_hubungan", anggota.ahli_waris_2_hubungan);

  return axios({
    method: "post",
    url: "/anggota",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function approve(anggota_id) {
  return axios.post(`/anggota/approve/${anggota_id}`);
}

function reject(anggota_id) {
  return axios.post(`/anggota/reject/${anggota_id}`);
}

function confirmPayment(anggota_id) {
  return axios.post(`/anggota/confirm_payment/${anggota_id}`);
}

function confirmProduction(anggota_id) {
  return axios.post(`/anggota/confirm_production/${anggota_id}`);
}

function getFotoIdentitas(id) {
  return axios.get(`/anggota/${id}/foto_identitas`, {
    responseType: "arraybuffer",
  });
}

function getFotoMemegangIdentitas(id) {
  return axios.get(`/anggota/${id}/foto_memegang_identitas`, {
    responseType: "arraybuffer",
  });
}

function getFotoDiri(id) {
  return axios.get(`/anggota/${id}/foto_diri`, {
    responseType: "arraybuffer",
  });
}

function getFotoBuktiPembayaran(id) {
  return axios.get(`/anggota/${id}/foto_bukti_pembayaran`, {
    responseType: "arraybuffer",
  });
}

function destroy(anggota_id) {
  return axios.delete(`/anggota/${anggota_id}`);
}

function uploadBuktiPembayaran(anggota_id, bukti_pembayaran) {
  var bodyFormData = new FormData();
  bodyFormData.append("bukti_pembayaran", bukti_pembayaran);

  return axios({
    method: "post",
    url: `/anggota/upload_payment_confirmation/${anggota_id}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function uploadFotoIdentitas(anggota_id, foto_identitas) {
  var bodyFormData = new FormData();
  bodyFormData.append("foto_identitas", foto_identitas);

  return axios({
    method: "post",
    url: `/anggota/upload_foto_identitas/${anggota_id}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function uploadFotoMemegangIdentitas(anggota_id, foto_memegang_identitas) {
  var bodyFormData = new FormData();
  bodyFormData.append("foto_memegang_identitas", foto_memegang_identitas);

  return axios({
    method: "post",
    url: `/anggota/upload_foto_memegang_identitas/${anggota_id}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function uploadFotoDiri(anggota_id, foto_diri) {
  var bodyFormData = new FormData();
  bodyFormData.append("foto_diri", foto_diri);

  return axios({
    method: "post",
    url: `/anggota/upload_foto_diri/${anggota_id}`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function update(anggota_id, anggota) {
  return axios.put(`/anggota/${anggota_id}`, anggota);
}

function resendEmailInfoPembayaran(id) {
  return axios.post(`/anggota/${id}/resend_email_info_pembayaran`);
}

function resendEmailInfoKTAOnProgress(id) {
  return axios.post(`/anggota/${id}/resend_email_info_kta_on_progress`);
}

function resendEmailInfoApproved(id) {
  return axios.post(`/anggota/${id}/resend_email_info_approved`);
}

async function exportToExcel(search, sort, order) {
  const {
    no_kta,
    nama,
    email,
    no_identitas,
    provinsi_id,
    kota_id,
    kecamatan_id,
    kelurahan_id,
    status,
    jenis_atribut,
    pekerjaan_id,
    hobi_id,
    createdAt,
  } = search;
  const raw_data = await axios.get("/anggota/export", {
    params: {
      no_kta: no_kta,
      nama: nama,
      email: email,
      no_identitas: no_identitas,
      provinsi_id: provinsi_id,
      kota_id: kota_id,
      kecamatan_id: kecamatan_id,
      kelurahan_id: kelurahan_id,
      status: status,
      jenis_atribut: jenis_atribut,
      pekerjaan_id: pekerjaan_id,
      hobi_id: hobi_id,
      order_by: sort,
      order_type: order,
      createdAt: createdAt,
    },
  });

  /* flatten objects */
  const rows = raw_data.data.rows.map((row) => ({
    nama: row.nama_lengkap,
    no_kta: row.nomor_kta,
    address: row.alamat,
    propinsi: row.Kecamatan.kota.propinsi.nama,
    kota: row.Kecamatan.kota.nama,
    kecamatan: row.Kecamatan.nama,
    nik: row.no_identitas,
    nama_alias: row.nama_panggilan,
    phone_number: row.no_hp,
    status_kta: row.status_anggota,
    email: row.email,
    jenis_kelamin: row.jenis_kelamin,
    tgl_lahir: row.tanggal_lahir,
    qr_code: row.qr_code,
    tgl_registrasi: row.createdAt,
    jenis_kta: row.jenis_atribut,
    pekerjaan: (row.Pekerjaan && row.Pekerjaan.nama) || "",
    hobi: (row.Hobi && row.Hobi.nama) || "",
  }));

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Name",
        "No KTA",
        "Address",
        "Propinsi",
        "Kota",
        "Kecamatan",
        "NIK",
        "Nama Alias",
        "Phone Number",
        "Status KTA",
        "Email",
        "Jenis Kelamin",
        "Tanggal Lahir",
        "QR Code",
        "Tanggal Registrasi",
        "Jenis KTA",
        "Pekerjaan",
        "Hobi",
      ],
    ],
    {
      origin: "A1",
    }
  );

  /* create an XLSX file and try to save to Anggota.xlsx */
  XLSX.writeFile(workbook, "Anggota.xlsx");
}
