import axios from "../plugins/axios_instance";

export const pekerjaanService = {
  loadAll,
  getAll,
  create,
  destroy,
  update,
};

function loadAll() {
  return axios.get("/pekerjaan/all").then((response) => {
    let pekerjaan = response.data;
    return pekerjaan;
  });
}

function getAll(limit, search, page, sort, order, kategori_pekerjaan_id) {
  return axios
    .get("/pekerjaan", {
      params: {
        limit,
        nama: search,
        page,
        order_by: sort,
        order_type: order,
        kategori_pekerjaan_id,
      },
    })
    .then((response) => {
      let pekerjaan = response.data;
      return pekerjaan;
    });
}

function create(pekerjaan) {
  return axios.post("/pekerjaan", { ...pekerjaan });
}

function update(pekerjaan, id) {
  return axios.put(`/pekerjaan/${id}`, pekerjaan);
}

function destroy(id) {
  return axios.delete(`/pekerjaan/${id}`);
}
