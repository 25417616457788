import Vue from "vue";
import VueRouter from "vue-router";
import AdminRoutes from "../modules/admin/admin_routes";
import AnggotaRoutes from "../modules/anggota/routes";
import { authenticationService } from "@/services";
import { Role } from "@/helpers";

Vue.use(VueRouter);

const routes = [
  {
    path: "/forbidden_403",
    name: "forbidden",
    component: () => import("@/modules/Forbidden.vue"),
  },
  {
    path: "/",
    name: "landing",
    component: () => import("@/modules/landing/Landing.vue"),
  },
  {
    path: "/change_password",
    name: "change_password",
    meta: { authorize: [Role.SuperUser, Role.Admin, Role.PAC, Role.DPC] },
    component: () => import("@/modules/ChangePassword.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/modules/login/Login.vue"),
  },
  {
    path: "/register/:id",
    // path: "/register/",
    name: "daftar-anggota",
    component: () => import("@/modules/daftar/DaftarAnggota.vue"),
  },
  {
    path: "/show_qr_code/",
    name: "show-qr-code",
    component: () => import("@/modules/qr-code/ShowQrCode.vue"),
  },
  {
    path: "/printout_qr_code/",
    name: "printout-qr-code",
    component: () => import("@/modules/qr-code/PrintoutQr.vue"),
  },
  {
    path: "/printout_unclaim_qr_code/",
    name: "printout-unclaim-qr-code",
    component: () => import("@/modules/qr-code/PrintoutUnclaimQr.vue"),
  },
  {
    path: "/authenticity/:qr_code",
    name: "qr-code-authenticity",
    component: () => import("@/modules/qr-code/QRCodeAuthenticity.vue"),
  },
  ...AdminRoutes,
  ...AnggotaRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = authenticationService.currentUserValue;

  if (authorize) {
    if (!currentUser) {
      // Redirect ke page login jika belum.
      return next({ path: "/login", query: { returnUrl: to.path } });
    }
    // Jika tidak terotorisasi, redirect ke forbidden page.
    if (authorize.length && !authorize.includes(currentUser.role)) {
      return next({ path: "/forbidden_403" });
    }
  }

  next();
});

export default router;
